import React, { useEffect, useRef, createRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "utils/StorageVariables";
import { Tooltip } from 'elements';

import { AssistantProvider } from "components/InputAreaAI";
import PlanShortcut from "components/PlanShortcut";
import {
  RetrieveMasterPlanDetails,
  RetrieveMasterPlanDetailsNull,
} from "Redux/RetrieveMasterPlan/action";
import _ from "lodash";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";

import * as Styles from "./styled.js";
import StepLayout from "../StepLayout/index";
import { PRIORITIZE_PATH, PURPOSE_PATH } from "utils/routes";

import { ReactComponent as WarningIcon } from "assets/images/journeys/warning.svg";
import useDeviceType from "../../../Hooks/ResponsiveHook";
import {
  CreateMetricsPopup,
  ManageMetricsPopup,
} from "./ManageMetrics/index";

import { GetPredefinedMetrics } from "Redux/GetPredefinedMetrics/action";
import { GetCustomMetrics } from "Redux/CustomMetrics/action";
import MeasurementItem from "./MeasurementItem";

const SortArr = (data) =>
  data?.sort(function (a, b) {
    return a?.goal?.priority - b?.goal?.priority;
  });

const Measurement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const [isAddCustomMetric, setIsAddCustomMetric] = useState(false);
  const [isManageMetric, setIsManageMetric] = useState(false);

  const $stepLayoutRef = useRef(null);
  const $inputRefs = useRef([]);

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );

  const { data: predefinedMetrics } = useSelector(
    (state) => state.predefinedMetricsReducer
  );

  const { data: customMetrics } = useSelector(
    (state) => state.customMetricsReducer
  );

  const { SelectedClient } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");

    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
    dispatch(GetPredefinedMetrics());

    return () => {
      dispatch(RetrieveMasterPlanDetailsNull(null));
    };
  }, []);

  useEffect(() => {
    if (SelectedClient !== null) {
      dispatch(GetCustomMetrics());
    }
  }, [SelectedClient]);

  const areas = SortArr([...(masterPlanDetails?.areas || [])]);

  const userData = JSON.parse(getCookie("userData"));

  // Initialize refs array
  if ($inputRefs.current.length !== areas.length) {
    $inputRefs.current = Array(areas.length)
      .fill(null)
      .map((_, i) => $inputRefs.current[i] || createRef());
  }

  const handlePrev = () => {
    navigate(PRIORITIZE_PATH);
    // navigate(GOALS_PATH);
  };

  const handleNext = () => {
    const formData = new FormData();
    formData.append("step", 3);
    dispatch(UpdateLastStep(formData));
    navigate(PURPOSE_PATH);
  };

  const onAddMetricPopupOpenHandle = () => {
    setIsAddCustomMetric(true);
  };

  const onAddMetricPopupCloseHandle = () => {
    setIsAddCustomMetric(false);
  };

  const onManageToggleHandle = (action) => {
    if (action) onAddMetricPopupOpenHandle();

    setTimeout(() => {
      if (isManageMetric) {
        $inputRefs.current.forEach(ref => {
          if (ref.current) {
            ref.current.closeAssistant();
          }
        });
      }
    }, 50);

    setIsManageMetric((prev) => !prev);
  };

  const handleEnterKeyDown = index => {
    const nextIndex = index + 1;

    if (nextIndex === $inputRefs.current.length) {
      $stepLayoutRef.current?.focusNext();
    }
    else if (nextIndex < $inputRefs.current.length) {
      $inputRefs.current[nextIndex].current?.focusInput();
    }
  }

  const firstIndex = areas.findIndex((item) => !item?.is_private);

  const HeaderContent = () => {
    return (
      <>
        <Styles.TableHeadCol $type="goal">
          3. Goal
        </Styles.TableHeadCol>

        <Styles.TableHeadCol $type="legacy"></Styles.TableHeadCol>
        <Styles.TableHeadCol $type="measurement">
          4. Measurement
          <Tooltip
            placement="leftTop"
            type="tertiary"
            title="Measurement"
            text="Represents the remaining distance to your desired state. It's a percentage that shows how close you are to where you want to be. It also helps in comparing the gap of one goal with the other goals. This gives you a benchmark on how things stand in your life."
            alternative
            align={{
              offset: [0, -2]
            }}
          >
            <WarningIcon />
          </Tooltip>
        </Styles.TableHeadCol>

        <Styles.TableHeadCol $type="gap">
          Gap
          <Tooltip
            placement="leftTop"
            type="tertiary"
            title="Gap"
            text="Represents the difference between your current status and your target goal."
            alternative
            align={{
              offset: [0, -2]
            }}
          >
            <WarningIcon />
          </Tooltip>
        </Styles.TableHeadCol>

        <Styles.TableHeadCol $type="gap-percent">
          Gap %
          <Tooltip
            placement="leftTop"
            type="tertiary"
            title="Gap %"
            text="Represents the remaining distance to your desired state. It's a percentage that shows how close you are to where you want to be. It also helps in comparing the gap of one goal with the other goals. This gives you a benchmark on how things stand in your life."
            alternative
            align={{
              offset: [0, -2]
            }}
          >
            <WarningIcon />
          </Tooltip>
        </Styles.TableHeadCol>

        <Styles.TableHeadCol $type="frequency">Frequency</Styles.TableHeadCol>

        {/* 🟠 According to the Figma design */}
        {/* {userData?.type === userTypes?.client && (
          <Styles.TableHeadCol>Private</Styles.TableHeadCol>
        )} */}
      </>
    );
  };

  return (
    <StepLayout
      title="3-4. Goal Statement & Measurement"
      header={isMobile || isTablet ? null : <HeaderContent />}
      onPrev={handlePrev}
      onNext={handleNext}
      ref={$stepLayoutRef}
    >
      <AssistantProvider>
        <Styles.Container>
          <Styles.ContainerItem>
            <Styles.TableContainer>
              {areas.map((item, i) =>
                <MeasurementItem
                  data={item}
                  index={i}
                  firstIndex={SelectedClient ? firstIndex : 0}
                  userDataId={userData?.id}
                  predefinedMetrics={predefinedMetrics}
                  customMetrics={customMetrics}
                  ref={$inputRefs.current[i]}
                  onAddMetricPopupOpen={onAddMetricPopupOpenHandle}
                  onManageToggle={onManageToggleHandle}
                  onEnterKeyDown={handleEnterKeyDown}
                  key={item?.id}
                />)}

              <PlanShortcut
                isVisible={masterPlanDetails?.viewed_masterplan_screen}
              />
            </Styles.TableContainer>
          </Styles.ContainerItem>
        </Styles.Container>
      </AssistantProvider>

      <CreateMetricsPopup
        active={isAddCustomMetric}
        onClose={onAddMetricPopupCloseHandle}
      />
      <ManageMetricsPopup
        active={isManageMetric}
        onClose={onManageToggleHandle}
      />
    </StepLayout>
  );
};

export default Measurement;
