import Cookies from "js-cookie";
import Notify from "utils/notify";

import { login } from "api";
import { IsUser } from "Redux/User/action";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { SetToken } from 'Redux/Token/action';
import { WELCOME_PATH, OTP_PATH } from 'utils/routes';
import { getAppBaseURL } from "utils/utility";

import { setUserId, track } from 'utils/amplitude';

export const LoginUser = (Data, Navigate) => async (dispatch) => {
  const url = getAppBaseURL();

  try {
    dispatch({ type: ActionTypes.LOADING });

    const dataForm = {
      ...Data,
      url: `https://${url}/reset-password?token=`
    }

    const { data, status } = await login(dataForm);
    
    setCookie("userDetail", JSON.stringify(data?.user));

    // Amplitude
    if (data.user.id) {
      setUserId(data.user.id);
      track('Logged In', {
        method: 'email'
      });
    }

    if (!data?.user?.is_verified) {
      dispatch({
        type: ActionTypes.ERROR,
        payload: data?.message,
      });

      Navigate(OTP_PATH);
      return;
    }

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      const goalSettingWorkbookKey = data.user.external_accounts.find(account => "goal_setting_workbook" in account);

      if (goalSettingWorkbookKey) {
        const goalSettingWorkbookValue = goalSettingWorkbookKey.goal_setting_workbook;
        setCookie("gps_id", goalSettingWorkbookValue);
      } else {
        console.log("Goal Setting Workbook Key not found.");
        setCookie("gps_id", null);
      }

      if (process.env.NODE_ENV === 'development') {
        Cookies.set('token-local', JSON.stringify(data));
      }

      dispatch(IsUser(data?.user));
      dispatch(SetToken(data?.token));

      Navigate(WELCOME_PATH, { replace: true });
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
