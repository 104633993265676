import React, { useState, useRef, useImperativeHandle, forwardRef } from "react";
import _ from "lodash";

import InputAreaAI from "components/InputAreaAI";
import LockedOver from "components/LockedOver/LockedOver";
import useDeviceType from "../../../../Hooks/ResponsiveHook";

import * as Styles from "../styled";

import { track } from 'utils/amplitude';

const PurposeItem = forwardRef(({ item, index: i, userData, onChange, onEnterKeyDown }, ref) => {
  const [pleasure, setPleasure] = useState(item?.goal?.pleasure);
  const [pain, setPain] = useState(item?.goal?.pain);

  const screenSizes = useDeviceType();

  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const $rewardsRef = useRef();
  const $consequencesRef = useRef();

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      $rewardsRef.current?.focus();
    }
  }));

  const handlePleasureChange = (value, _, ai) => {
    setPleasure(value);

    if (ai) {
      onChange(item?.id, 'pleasure', value);

      // Amplitude
      track("Goal Pleasure Entered", {
        goal_pleasure: value,
        goal_id: item?.id,
        with_ai: true
      });
    }
  }

  const handlePainChange = (value, _, ai) => {
    setPain(value);

    if (ai) {
      onChange(item?.id, 'pain', value);

      // Amplitude
      track("Goal Pain Entered", {
        goal_pain: value,
        goal_id: item?.id,
        with_ai: true
      });
    }
  }

  const handlePleasureKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      $consequencesRef.current?.focus();
    }
  }

  const handlePainKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      onEnterKeyDown(i);
    }
  }

  const handlePleasureBlur = () => {
    onChange(item?.id, 'pleasure', pleasure);

    // Amplitude
    track("Goal Pleasure Entered", {
      goal_pleasure: pleasure,
      goal_id: item?.id,
      with_ai: false
    });
  }

  const handlePainBlur = () => {
    onChange(item?.id, 'pain', pain);

    // Amplitude
    track("Goal Pain Entered", {
      goal_pain: pain,
      goal_id: item?.id,
      with_ai: false
    });
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      e.target.blur();
    }
  }

  return (
    <Styles.Row key={item?.id} border={item?.color}>
      {item?.is_private && userData?.id !== item?.user && (
        <LockedOver index={i + 1} width="50px" />
      )}
      <Styles.GoalActionWrap>
        <Styles.ItemContainer>
          <Styles.LeftSection>
            <Styles.WrapAvatar>
              <Styles.IdAvatar size={24}>{i + 1}</Styles.IdAvatar>
            </Styles.WrapAvatar>

            <Styles.WrapHead>
              <Styles.TitleWrap>
                <Styles.Text $area title={item.name}>{item.name}</Styles.Text>
                <Styles.Text $category title={item?.goal?.category_name}>
                  {item?.goal?.category_name}
                </Styles.Text>
              </Styles.TitleWrap>
              {!isMobile && <Styles.Text $goal>
                {item?.goal?.goal_name}
              </Styles.Text>}
            </Styles.WrapHead>
          </Styles.LeftSection>
          {isMobile && <Styles.Text $weight={400} $mt={15} >
            {item?.goal?.goal_name}
          </Styles.Text> }
          {(isMobile || isTablet) &&
            <Styles.TitleWrap $mt={15}>
              <Styles.MiddleText>PURPOSE</Styles.MiddleText>
            </Styles.TitleWrap>
          }
          <Styles.MidSection>
            <Styles.InputWrapper>
              <Styles.Label>Rewards</Styles.Label>
              <InputAreaAI
                id={`rewards_${item?.id}`}
                name="pleasure"
                value={item?.goal?.pleasure}
                placeholder="Rewards for Achievement"
                onChange={handlePleasureChange}
                onKeyUp={handleKeyUp}
                onKeyDown={handlePleasureKeyDown}
                onBlur={handlePleasureBlur}
                style={{ wordBreak: 'break-word' }}
                max={255}
                ref={$rewardsRef}
                assistant={{
                  type: 'reward',
                  color: item?.color,
                  title: "Rewards",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  prompt: item?.goal?.pleasure
                }}
              />
            </Styles.InputWrapper>

            <Styles.InputWrapper>
              <Styles.Label>Consequences</Styles.Label>
              <InputAreaAI
                id={`consequences_${item?.id}`}
                name="pain"
                value={item?.goal?.pain}
                placeholder="Consequences for Non-Achievement"
                onChange={handlePainChange}
                onKeyUp={handleKeyUp}
                onKeyDown={handlePainKeyDown}
                onBlur={handlePainBlur}
                style={{ wordBreak: 'break-word' }}
                max={255}
                ref={$consequencesRef}
                assistant={{
                  type: 'consequence',
                  color: item?.color,
                  title: "Consequences",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  prompt: item?.goal?.pain
                }}
              />
            </Styles.InputWrapper>
          </Styles.MidSection>
        </Styles.ItemContainer>
      </Styles.GoalActionWrap>
    </Styles.Row>
  )
});

export default PurposeItem;
